/*
 * Yafeapedia
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.64
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from "../ApiClient";
import PageDeletedItemDTO from '../model/PageDeletedItemDTO';

/**
* DeletedItemController service.
* @module api/DeletedItemControllerApi
* @version 1.0.1
*/
export default class DeletedItemControllerApi {

    /**
    * Constructs a new DeletedItemControllerApi. 
    * @alias module:api/DeletedItemControllerApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the emptyTrash operation.
     * @callback moduleapi/DeletedItemControllerApi~emptyTrashCallback
     * @param {String} error Error message, if any.
     * @param {'Boolean'{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} authorization 
     * @param {module:api/DeletedItemControllerApi~emptyTrashCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    emptyTrash(authorization, callback) {
      
      let postBody = null;
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling emptyTrash");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        'Authorization': authorization
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = 'Boolean';

      return this.apiClient.callApi(
        '/v1/trash/', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getDeletedItems operation.
     * @callback moduleapi/DeletedItemControllerApi~getDeletedItemsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PageDeletedItemDTO{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} authorization 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page  (default to <.>)
     * @param {Number} opts.size  (default to <.>)
     * @param {module:api/DeletedItemControllerApi~getDeletedItemsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getDeletedItems(authorization, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling getDeletedItems");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'page': opts['page'],'size': opts['size']
      };
      let headerParams = {
        'Authorization': authorization
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = PageDeletedItemDTO;

      return this.apiClient.callApi(
        '/v1/trash/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the permanentDelete operation.
     * @callback moduleapi/DeletedItemControllerApi~permanentDeleteCallback
     * @param {String} error Error message, if any.
     * @param {'Boolean'{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} id 
     * @param {Boolean} isMedia 
     * @param {String} authorization 
     * @param {module:api/DeletedItemControllerApi~permanentDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    permanentDelete(id, isMedia, authorization, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling permanentDelete");
      }
      // verify the required parameter 'isMedia' is set
      if (isMedia === undefined || isMedia === null) {
        throw new Error("Missing the required parameter 'isMedia' when calling permanentDelete");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling permanentDelete");
      }

      let pathParams = {
        'id': id,'isMedia': isMedia
      };
      let queryParams = {
        
      };
      let headerParams = {
        'Authorization': authorization
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = 'Boolean';

      return this.apiClient.callApi(
        '/v1/trash/{id}/{isMedia}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the restore operation.
     * @callback moduleapi/DeletedItemControllerApi~restoreCallback
     * @param {String} error Error message, if any.
     * @param {'Boolean'{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} id 
     * @param {Boolean} isMedia 
     * @param {String} authorization 
     * @param {module:api/DeletedItemControllerApi~restoreCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    restore(id, isMedia, authorization, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling restore");
      }
      // verify the required parameter 'isMedia' is set
      if (isMedia === undefined || isMedia === null) {
        throw new Error("Missing the required parameter 'isMedia' when calling restore");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling restore");
      }

      let pathParams = {
        'id': id,'isMedia': isMedia
      };
      let queryParams = {
        
      };
      let headerParams = {
        'Authorization': authorization
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = 'Boolean';

      return this.apiClient.callApi(
        '/v1/trash/restore/{id}/{isMedia}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
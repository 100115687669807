<template>
    <div class="delete-button-container">
        <button class="btn btn-action delete-button" @click="showConfirmDialog = true">
            {{ buttonText }}
        </button>
        <div v-if="showConfirmDialog" class="confirm-dialog-overlay" @click="cancelDelete">
            <div class="confirm-dialog" @click.stop>
                <p>{{ deleteMessage }}</p>
                <div class="button-group">
                    <button @click="confirmDelete" class="confirm-btn">نعم</button>
                    <button @click="cancelDelete" class="cancel-btn">لا</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';

const emits = defineEmits(["delete"]);
const props = defineProps(["id", "isMedia", "isPermenant", "buttonText"]);

const showConfirmDialog = ref(false);

const deleteMessage = computed(() => {
    return props.isPermenant
        ? "لن تستطيع إسترجاع البيانات بعد الحذف؟"
        : "هل أنت متأكد أنك تريد حذف هذه المدخلات؟";
});

const buttonText = computed(() => {
    return props.buttonText || "حذف";
})

const confirmDelete = () => {
    var i = props.isMedia ? props.isMedia : 0;
    emits("delete", props.id, i);
    showConfirmDialog.value = false;
}

const cancelDelete = () => {
    showConfirmDialog.value = false;
}
</script>

<style scoped>
.delete-button-container {
    position: relative;
}

.confirm-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.confirm-dialog {
    position: relative;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.confirm-btn,
.cancel-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
}

.confirm-btn {
    background-color: #e74c3c;
    color: white;
}

.confirm-btn:hover {
    background-color: #c0392b;
}

.cancel-btn {
    background-color: #3498db;
    color: white;
}

.cancel-btn:hover {
    background-color: #2980b9;
}

.delete-button {
    width: fit-content;
    min-width: min-content;
    white-space: nowrap;
}
</style>

<template>   
    <button class="btn" @click="addMedia">
        {{ mediaType == 0 ? 'إضافة صورة' : 'إضافة صوت' }}
    </button>
    <div class="gallery1">
        <div v-for="media in mediaList" :key="media._key || media.id">
            <MediaContent 
                :media="media" 
                @deleteMedia="deleteMedia"
                :mediaType="mediaType"
                :_key="String(media._key || media.id)"
                @updateMedia="updateMetaData" />
        </div>
    </div>                             
</template>

<script setup>
import MediaContent from './MediaContent.vue';
import { ref, watch } from "vue";
import { generateRandomString } from "../utils/utils.js";
import { isBigFile, isVeryBigFile } from '@/firebase/Utils.js'

const props = defineProps({
    media: {
        type: Array,
        default: () => []
    },
    mediaType: {
        type: Number,
        default: 0
    },
    requests: {
        type: Number,
        default: 0
    }
});

const _emits = defineEmits(['update:media', 'postMedia']);
const mediaList = ref([]);
const mediaType = ref(0);

const acceptableImageTypes = ['image/jpeg', 'image/png'];
const acceptableAudioTypes = ['audio/mpeg', 'audio/mp3'];

// Watch for changes in props
watch(() => props.mediaType, (newType) => {
    mediaType.value = newType;
});

watch(() => props.requests, (newRequests) => {
    if (newRequests === 1) {
        _emits("postMedia", mediaList.value.map(media => ({
            id: media.id || undefined,
            mediaUrl: media.mediaUrl,
            title: media.title || '',
            description: media.description || '',
            file: media.file // Will be handled by parent component for upload
        })));
    }
});

watch(() => props.media, (newMedia) => {
    if (newMedia) {
        mediaList.value = newMedia.map(media => ({
            ...media,
            _key: String(media._key || media.id || generateRandomString(10))
        }));
    }
});

function addMedia() {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.accept = mediaType.value === 0 ? acceptableImageTypes.join(',') : acceptableAudioTypes.join(',');
    
    fileInput.onchange = async (e) => {
        const files = Array.from(e.target.files);
        let hasError = false;

        const newMedia = files.filter(file => {
            const isCorrectType = mediaType.value === 0 ? 
                acceptableImageTypes.includes(file.type) : 
                acceptableAudioTypes.includes(file.type);
                
            if (!isCorrectType ||(mediaType.value==0? isBigFile(file):isVeryBigFile(file))) {
                hasError = true;
                return false;
            }
            return true;
        }).map(file => ({
            mediaUrl: URL.createObjectURL(file),
            file: file,
            _key: generateRandomString(10),
            title: '',
            description: ''
        }));

        mediaList.value = [...mediaList.value, ...newMedia];
        
        if (hasError) {
            alert(`يجب أن لا يتجاوز حجم الملف ${mediaType.value==0?1:6} MB`);
        }
    };
    
    fileInput.click();
}

function updateMetaData(newData) {
    const index = mediaList.value.findIndex(media => {
        const mediaKey = String(media._key || media.id);
        const newDataKey = String(newData._key || newData.id);
        return mediaKey === newDataKey;
    });
    if (index !== -1) {
        mediaList.value[index] = {
            ...mediaList.value[index],
            title: newData.title,
            description: newData.description
        };
    }
}

function deleteMedia(data) {
    const searchKey = String(data._key || data.id);
    mediaList.value = mediaList.value.filter(media => 
        String(media._key || media.id) !== searchKey
    );
}
</script>

<style scoped>

.gallery1 {
    display: inline-block;
    white-space: nowrap;
    overflow-x: auto;
    /* Add horizontal scrollbar if content overflows */
}

.gallery1>div {
    display: inline-block;
    vertical-align: top;
    /* Aligns items vertically at the top */
}

*::-webkit-scrollbar {
    width: 2px;
    background-color: rgb(255, 255, 255);
}

*::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(255, 253, 253);
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(241, 88, 39);
    outline: 1px solid rgb(241, 88, 39);
}

.btn {
    border-radius: 0.25rem;
    padding: 0.75rem 1.5rem;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
}

</style>
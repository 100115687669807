<template>
    <div class="form-group">
        <div class="select-header">
            <label :for="id">{{ label }}:</label>
            <button type="button" class="search-toggle" @click="toggleSearch">
                <i v-if="!isSearchActive" class="fas fa-search"></i>
                <i v-else class="fas fa-times"></i>
            </button>
        </div>
        
        <div v-if="isSearchActive" class="search-container">
            <input 
                type="text" 
                v-model="searchQuery" 
                class="search-input" 
                placeholder="البحث..."
                @input="openSelect"
            >
        </div>

        <select 
            :id="id" 
            :value="selectedValue"
            @change="handleChange($event)" 
            class="form-control"
            :class="{ 'with-search': isSearchActive, 'is-expanded': isExpanded }"
            ref="selectElement"
        >
            <option v-if="hasDefaultOption" value="" disabled>{{ defaultOptionText }}</option>
            <option v-for="option in filteredOptions" :value="option.id" :key="option.id">
                {{ formatOptionLabel(option) }}
            </option>
        </select>
    </div>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted, watch } from 'vue';

const props = defineProps({
    modelValue: {
        type: [Object, String],
        default: null
    },
    options: {
        type: Array,
        required: true
    },
    label: {
        type: String,
        required: true
    },
    id: {
        type: String,
        required: true
    },
    hasDefaultOption: {
        type: Boolean,
        default: false
    },
    defaultOptionText: {
        type: String,
        default: 'اختر'
    },
    showParent: {
        type: Boolean,
        default: false
    },
    catId: {
        type: [Number, String],
        default: null
    }
});

const emit = defineEmits(['update:modelValue']);

const isSearchActive = ref(false);
const searchQuery = ref('');
const selectElement = ref(null);
const isExpanded = ref(false);
const selectedOption = ref(null);

const selectedValue = computed(() => {
    if (props.catId) {
        return props.catId;
    }
    return props.modelValue?.id || props.modelValue || '';
});

const filteredOptions = computed(() => {
    if (!searchQuery.value) return props.options;
    return props.options.filter(option => 
        formatOptionLabel(option)
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase())
    );
});

watch(() => props.modelValue, (newValue) => {
    if (newValue) {
        const option = props.options.find(opt => opt.id === Number(newValue));
        selectedOption.value = option;
    } else {
        selectedOption.value = null;
    }
}, { immediate: true });

watch(() => props.catId, (newCatId) => {
    if (newCatId) {
        const option = props.options.find(opt => opt.id === newCatId);
        if (option) {
            selectedOption.value = option;
            emit('update:modelValue', option.id);
        }
    }
}, { immediate: true });

function handleChange(event) {
    const selectedId = event.target.value;
    if (selectedId) {
        const newSelectedOption = props.options.find(opt => opt.id === Number(selectedId));
        selectedOption.value = newSelectedOption;
        emit('update:modelValue', selectedId);
        closeSelect();
    } else {
        selectedOption.value = null;
        emit('update:modelValue', null);
    }
}

function openSelect() {
    if (selectElement.value) {
        selectElement.value.size = 4;
        isExpanded.value = true;
    }
}

function closeSelect() {
    if (selectElement.value) {
        selectElement.value.size = 1;
        isExpanded.value = false;
    }
}

function toggleSearch() {
    isSearchActive.value = !isSearchActive.value;
    if (!isSearchActive.value) {
        searchQuery.value = '';
        closeSelect();
    } else {
        openSelect();
    }
}

function formatOptionLabel(option) {
    if (selectedOption.value && option.id === selectedOption.value.id) {
        if (props.showParent && selectedOption.value.parent) {
            return `${selectedOption.value.nameAr} - ${selectedOption.value.parent.nameAr}`;
        }
        return selectedOption.value.nameAr;
    }
    
    if (props.showParent && option.parent) {
        return `${option.nameAr} - ${option.parent.nameAr}`;
    }
    return option.nameAr;
}

onMounted(() => {
    document.addEventListener('click', (event) => {
        if (!event.target.closest('.form-group')) {
            closeSelect();
        }
    });
});

onUnmounted(() => {
    document.removeEventListener('click', closeSelect);
});
</script>

<style scoped>
.form-group {
    margin-bottom: 1.5rem;
    position: relative;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--primary-dark);
    font-weight: 600;
    font-size: 0.95rem;
}

.form-control {
    width: 100%;
    padding: 8px 12px !important;
    border: 1.5px solid var(--border-color);
    border-radius: 0.5rem;
    transition: all 0.2s ease-in-out;
    font-size: 1rem;
    background-color: #fff;
    cursor: pointer;
    appearance: none;
    position: relative;
}

.form-control::after {
    content: "\f107"; /* Font Awesome chevron-down unicode */
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.form-control:hover {
    border-color: var(--primary-color);
}

.form-control:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(var(--primary-color-rgb), 0.15);
}

/* Add disabled state styling */
.form-control:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 0.7;
}

/* Optional: Style the options */
.form-control option {
    padding: 8px 12px;
}

.select-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.search-toggle {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.search-toggle:hover {
    background-color: var(--border-color);
}

.search-container {
    margin-bottom: 0.5rem;
}

.search-input {
    width: 100%;
    padding: 8px 12px;
    border: 1.5px solid var(--border-color);
    border-radius: 0.5rem;
    font-size: 0.9rem;
    background-color: white;
    margin-bottom: 4px;
}

.search-input:focus {
    outline: none;
    border-color: var(--primary-color);
}

/* Styles for when select is expanded */
.form-control.is-expanded {
    background-image: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    border: 1.5px solid var(--border-color);
    border-radius: 0.5rem;
    background-color: white;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 
                0 2px 4px -1px rgba(0, 0, 0, 0.06);
    padding: 4px 0 !important;
}

/* Adjust margin when search is active */
.form-control.with-search {
    margin-top: 0;
}

/* Option styles */
.form-control option {
    padding: 8px 12px;
}

.form-control.is-expanded option:hover {
    background-color: var(--border-color);
    cursor: pointer;
}
</style>

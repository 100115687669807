<template>
    <div class="trash-wrapper">
        <div class="container-fluid trash-container">
            <h2 class="text-center mb-4">سلة المحذوفات</h2>
            <div class="row mb-4">
                <div class="col-12 d-flex flex-wrap justify-content-between align-items-center">
                    <DeleteButtonComponent :id="`empty-trash`"
                                    :isPermenant="true"
                                    :button-text="`حذف الكل: ${totalElements}`"
                                    @delete="permanentDelete(`empty-trash`, 2)" />
                </div>
            </div>

            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>العنوان</th>
                            <th>الكاتب</th>
                            <th>القسم</th>
                            <th>النوع</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in deletedItems" :key="item.id">
                            <td>{{ item.title }}</td>
                            <td>{{ item.author }}</td>
                            <td>{{ item.category }}</td>
                            <td>{{ item.isMedia === 1 ? "وسائط" : "مقال" }}</td>
                            <td>
                                <div class="btn-group" role="group">
                                    <button class="btn btn-success" @click="restoreItem(item.id, item.isMedia)">
                                        استعادة
                                    </button>
                                    <DeleteButtonComponent :id="item.id" :isMedia="item.isMedia"
                                    :isPermenant="true"
                                        @delete="permanentDelete(item.id, item.isMedia)" />

                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="d-flex justify-content-end">
                        <PaginationComponent :total-elements="totalElements" :page-size="pageSize"
                            :current-page="currentPage" @page-changed="handlePageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { ApiClient, DeletedItemControllerApi } from "../api/index.js";
import PaginationComponent from '@/components/PaginationComponent.vue';
import DeleteButtonComponent from '@/components/DeleteButtonComponent.vue';

const apiClient = new ApiClient();
const trashApi = new DeletedItemControllerApi(apiClient);

const deletedItems = ref([]);
const searchMode = ref(false);
const query = ref(null);
const totalElements = ref(0);
const pageSize = ref(10);
const currentPage = ref(0);

const fetchDeletedItems = () => {
    if (!searchMode.value) {
        trashApi.getDeletedItems(
            localStorage.getItem("token"),
            { page: currentPage.value, size: pageSize.value },
            (error, data, response) => {
                if (data) parseResult(data);
            }
        );
    }//  else {
    //     trashApi.searchDeletedItems(
    //         currentPage.value,
    //         pageSize.value,
    //         query.value,
    //         localStorage.getItem("token"),
    //         (error, data, response) => {
    //             if (data) parseResult(data);
    //         }
    //     );
    // }
};

const parseResult = (data) => {
    deletedItems.value = data.content;
    currentPage.value = data.pageable.pageNumber;
    pageSize.value = data.pageable.pageSize;
    totalElements.value = data.totalElements;
};

const restoreItem = (id, isMedia) => {
    trashApi.restore(id, isMedia, localStorage.getItem("token"), (error, data, response) => {
        if (data === true) fetchDeletedItems();
    });
};

const permanentDelete = (id, isMedia) => {
    if(id=='empty-trash')
    trashApi.emptyTrash(localStorage.getItem('token'),(error,data,response)=>{
        if(data===true) fetchDeletedItems();
    });
    else
        trashApi.permanentDelete(id, isMedia, localStorage.getItem("token"), (error, data, response) => {
            if (data === true) fetchDeletedItems();
        });
};

const startSearch = (searchQuery) => {
    currentPage.value = 0;
    searchMode.value = true;
    query.value = searchQuery;
    fetchDeletedItems();
};

const handlePageChange = (newPage) => {
    currentPage.value = newPage;
    fetchDeletedItems();
};

fetchDeletedItems();
</script>

<style scoped>
.trash-wrapper {
    padding: 1rem;
    min-height: 100vh;
    background-color: var(--background-light);
}

.trash-container {
    background-color: white;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

/* Rest of the styles are the same as Articles.vue */
.table {
    background-color: white;
    border-radius: 0.5rem;
    overflow: hidden;
}

.table thead {
    background-color: var(--primary-color);
    color: var(--text-light);
}

.table th,
.table td {
    vertical-align: middle;
}

.btn-group .btn {
    margin: 0 0.25rem;
}

/* Add responsive styles similar to Articles.vue if needed */
</style>
import { getDatabase, ref, set } from "firebase/database";
import { getStorage, ref as sRef, uploadBytes,getDownloadURL  } from "firebase/storage";


export async function saveToFirebase(path, data) {
    const database = getDatabase();
    await set(ref(database, path), data);
}


export async function uploadFileToFirebase(file, folder, fileName) {
    try {
        if (isBigFile(file)) {
            alert('حجم الملف يجب ان يكون اقل من 1 ميجا');
            throw new Error('File size exceeds 1 MB limit');
        }

        const storage = getStorage();
        const storageRef = sRef(storage, `${folder}/${fileName}`);
        var snapshot = await uploadBytes(storageRef, file);
        if (snapshot) {
            return await getDownloadURL(snapshot.ref);
        }
        return null;
    } catch (error) {
        console.error('Error uploading file to Firebase Storage:', error);
        throw error;
    }
}

export async function uploadAudioToFirebase(file, folder, fileName) {
    try {
        if (isVeryBigFile(file)) {
            alert('حجم الملف يجب ان يكون اقل من 6 ميجا');
            throw new Error('File size exceeds 6 MB limit');
        }

        const storage = getStorage();
        const storageRef = sRef(storage, `${folder}/${fileName}`);
        var snapshot = await uploadBytes(storageRef, file);
        if (snapshot) {
            return await getDownloadURL(snapshot.ref);
        }
        return null;
    } catch (error) {
        console.error('Error uploading file to Firebase Storage:', error);
        throw error;
    }
}

export function isBigFile(file){
    const maxSize = 1 * 1024 * 1024; // 1 MB
    return file.size > maxSize;        
}
export function isVeryBigFile(file){
    const maxSize = 6 * 1024 * 1024; // 6 MB
    return file.size > maxSize;        
}

/*
 * Yafeapedia
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.64
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import AuthorDTO from './AuthorDTO';
import CategoryDTO from './CategoryDTO';
import MediaDTO from './MediaDTO';

/**
 * The AlbumDTO model module.
 * @module model/AlbumDTO
 * @version 1.0.1
 */
export default class AlbumDTO {
  /**
   * Constructs a new <code>AlbumDTO</code>.
   * @alias module:model/AlbumDTO
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>AlbumDTO</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AlbumDTO} obj Optional instance to populate.
   * @return {module:model/AlbumDTO} The populated <code>AlbumDTO</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AlbumDTO();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('contentEntities'))
        obj.contentEntities = ApiClient.convertToType(data['contentEntities'], [MediaDTO]);
      if (data.hasOwnProperty('category'))
        obj.category = CategoryDTO.constructFromObject(data['category']);
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('imgUrl'))
        obj.imgUrl = ApiClient.convertToType(data['imgUrl'], 'String');
      if (data.hasOwnProperty('lang'))
        obj.lang = ApiClient.convertToType(data['lang'], 'Number');
      if (data.hasOwnProperty('addedAt'))
        obj.addedAt = ApiClient.convertToType(data['addedAt'], 'Date');
      if (data.hasOwnProperty('mediaType'))
        obj.mediaType = ApiClient.convertToType(data['mediaType'], 'Number');
      if (data.hasOwnProperty('lastEdit'))
        obj.lastEdit = ApiClient.convertToType(data['lastEdit'], 'Date');
      if (data.hasOwnProperty('author'))
        obj.author = AuthorDTO.constructFromObject(data['author']);
      if (data.hasOwnProperty('lastEditBy'))
        obj.lastEditBy = AuthorDTO.constructFromObject(data['lastEditBy']);
      if (data.hasOwnProperty('deletedAt'))
        obj.deletedAt = ApiClient.convertToType(data['deletedAt'], 'Date');
      if (data.hasOwnProperty('published'))
        obj.published = ApiClient.convertToType(data['published'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
AlbumDTO.prototype.id = undefined;

/**
 * @member {Array.<module:model/MediaDTO>} contentEntities
 */
AlbumDTO.prototype.contentEntities = undefined;

/**
 * @member {module:model/CategoryDTO} category
 */
AlbumDTO.prototype.category = undefined;

/**
 * @member {String} title
 */
AlbumDTO.prototype.title = undefined;

/**
 * @member {String} description
 */
AlbumDTO.prototype.description = undefined;

/**
 * @member {String} imgUrl
 */
AlbumDTO.prototype.imgUrl = undefined;

/**
 * @member {Number} lang
 */
AlbumDTO.prototype.lang = undefined;

/**
 * @member {Date} addedAt
 */
AlbumDTO.prototype.addedAt = undefined;

/**
 * @member {Number} mediaType
 */
AlbumDTO.prototype.mediaType = undefined;

/**
 * @member {Date} lastEdit
 */
AlbumDTO.prototype.lastEdit = undefined;

/**
 * @member {module:model/AuthorDTO} author
 */
AlbumDTO.prototype.author = undefined;

/**
 * @member {module:model/AuthorDTO} lastEditBy
 */
AlbumDTO.prototype.lastEditBy = undefined;

/**
 * @member {Date} deletedAt
 */
AlbumDTO.prototype.deletedAt = undefined;

/**
 * @member {Boolean} published
 */
AlbumDTO.prototype.published = undefined;


<template>
  
<section class="login">
    
    <div class="container bg-page">
      <div class="login-box d-flex flex-column justify-content-center">
        <img src="../assets/logo-small.png" class="h-10" >
        <br>
        <br>
        <br>
        <br>
        <form @submit="handleSubmit">
          <div class="user-box">
            <input type="text"  required v-model="username" autocomplete="username">
            <label>اسم المستخدم</label>
          </div>
          <div class="user-box">
            <input type="password" required v-model="password" ref="passwordInput" autocomplete="current-password">
            <label>كلمة المرور</label>
            <i class="fas fa-eye show-pass" @click="togglePasswordVisibility"></i>
          </div>
          <p v-if="errorMsg" class="error">{{ errorMsg }}</p>
          <button type="submit" class="btn btn-outline-primary w-100">
           دخول
          </button>
        </form>
      </div>
    </div>    
</section>


</template>

<style>
:root {
  --main-color: #600b0e;
  --scond-color: #f15827;
  --text-color: #f9f9f9;
  --section-background: #f4f4f4;
  --main-color-transition: 0.3s;
  --main-color-padding-top: 100px;
  --main-color-padding-bottom: 100px;

}
  body{
    overflow: auto;
  }
  .bg-page{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1051;
    overflow: hidden;
    outline: 0;
  }
  .bg-page {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 300% 300%;
  background-image: linear-gradient(
    -45deg,
    var(--main-color) 0%,
    var(--scond-color) 25%,
    var(--main-color) 51%,
    var(--scond-color) 100%
  );
  -webkit-animation: AnimateBG 20s ease infinite;
  animation: AnimateBG 20s ease infinite;
}

@-webkit-keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 80%;
  padding: 40px;
  background: #ffffff;
  color: #f4f4f4;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.159);
  border-radius: 10px;
}
@media (max-width: 768px) {
  .login-box {
    width: 90%; 
  }
}

.login-box h3 {
  margin: 0 0 30px;
  padding: 0;
  color: var(--main-color);

}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: var(--scond-color);
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid var(--main-color);
  outline: none;
  background: transparent;
}
.login-box .user-box label {
  position: absolute;
  top:0;
  right: 0;
  padding: 10px 0;
  font-size: 16px;
  color: var(--scond-color);
  pointer-events: none;
  transition: .5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  right: 0;
  color: var(--main-color);
  font-size: 12px;
}

.login-box form a {
  position: inherit;
  display: block;
  text-align: center;
  transform: translateY(-5px);
  padding: 10px 20px;
  background: #600b0e;
  color: var(--scond-color);
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 4px;
}

.login-box a:hover {
  background: var(--scond-color);
  color: var(--text-color);
  border-radius: 5px;
  box-shadow: 0 0 15px #600b0e44,
              0 0 15px #600b0e44,
              0 0 15px #600b0e44,
              0 0 15px #600b0e44;
  transform: translateY(-10px);

}

.login-box a span {
  position: absolute;
  display: block;
}

.login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #f15827);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #f15827);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #f15827);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #f15827);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}
.show-pass{
  position: absolute;
  color: var(--scond-color);
  left: 0;
  top: 13px;
  font-size: 20px;
}
.show-pass:hover{
  transform: translateY(-3px);
}

</style>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import {ApiClient,AuthenticationControllerApi} from '@/api/index.js'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";


const username = ref('');
const password = ref('');
const errorMsg = ref(null);


const passwordVisible = ref(false);

const apiClient = new ApiClient();
const authenticationController = new AuthenticationControllerApi(apiClient);
const auth = getAuth();


const router = useRouter();

const passwordInput = ref(null);

const authenticateUser = () => {
  authenticationController.authenticate({
    username: username.value,
    password: password.value
  }, (error, data, response) => {
    try {
      if (data.tdata) {


        signInWithEmailAndPassword(auth, `${username.value}@yafeapedia.com`, password.value)
  .then((userCredential) => {
  })
  .catch((error) => {
    const errorMessage = error.message;
    alert(error.message);
  });
        const currentTime = new Date().getTime();
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('token', `Bearer ${data.tdata}`);
        localStorage.setItem('author', data.id);
        localStorage.setItem('authTime', currentTime.toString()); // Store the login time
        localStorage.setItem('isHPUser', data.auth);
        localStorage.setItem('displayName', data.displayName);
        if(data.auth==true)
        router.push('/home/dashboard');
        else
        router.push('/myArticles');
      }
    } catch (exception) {
      errorMsg.value = "كلمة السر أو اسم المستخدم خطأ";
    }
  });
};

const handleSubmit = (event) => {
  event.preventDefault();
  authenticateUser();
};

const togglePasswordVisibility = () => {
  passwordVisible.value = !passwordVisible.value;
  if (passwordInput.value) {
    passwordInput.value.type = passwordVisible.value ? 'text' : 'password';
  }
};

onMounted(() => {
  if(localStorage.getItem('expired') === 'true'){
   errorMsg.value = 'إنتهت الجلسة!';
   localStorage.removeItem('expired');
  }
})
</script>
<template>
    <div class="menu-button-container">
      <button class="btn btn-action" @click="toggleMenu">
        <i class="fas fa-ellipsis-v"></i>
      </button>
      
      <div v-if="showMenu" class="menu-popup">
        <a :href="url" target="_blank" class="menu-item">
          <i class="fas fa-eye"></i>
          عرض
        </a>
        <div class="menu-item" @click="handleAction">
          <i :class="actionIcon"></i>
          {{ actionText }}
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted,onUnmounted, nextTick } from 'vue';
  
  const props = defineProps({
    url: {
      type: String,
      required: true
    },
    actionText: {
      type: String,
      required: true
    },
    actionIcon: {
      type: String,
      default: 'fas fa-paper-plane'
    }
  });
  
  const emit = defineEmits(['action']);
  
  const showMenu = ref(false);
  
  const toggleMenu = (event) => {
    showMenu.value = !showMenu.value;
    
    if (showMenu.value) {
      nextTick(() => {
        const button = event.currentTarget;
        const menu = document.querySelector('.menu-popup');
        const rect = button.getBoundingClientRect();
        
        menu.style.top = `${rect.bottom + 5}px`;
        menu.style.left = `${rect.right - menu.offsetWidth}px`;
      });
    }
  };
  
  const handleAction = () => {
    emit('action');
    showMenu.value = false;
  };
  
  // Close menu when clicking outside
  const handleClickOutside = (event) => {
    const menuContainer = event.target.closest('.menu-button-container');
    if (!menuContainer && showMenu.value) {
      showMenu.value = false;
    }
  };
  
  onMounted(() => {
    document.addEventListener('click', handleClickOutside);
  });
  
  onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
  });
  </script>
  
  <style scoped>
  .menu-button-container {
    position: relative;
    display: inline-block;
  }
  
  .menu-popup {
    position: fixed;
    z-index: 9999;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    min-width: 150px;
    animation: menuAppear 0.3s ease;
  }
  
  .menu-item {
    padding: 10px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;
    transition: all 0.2s ease;
    color: #4a5568;
    text-decoration: none;
  }
  
  .menu-item:hover {
    background: #f7fafc;
    color: #2d3748;
  }
  
  .menu-item i {
    font-size: 0.9em;
    width: 16px;
    text-align: center;
  }
  
  @keyframes menuAppear {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  </style>
<template>
    <div>
        <div class="modal fade" id="albumModal" tabindex="-1" role="dialog" aria-labelledby="albumModalLabel"
            aria-hidden="true" ref="modal" :class="{ 'modal-static': isSaving }">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="albumModalLabel">{{ title }}</h5>
                    </div>
                    <div class="modal-body">
                        <div style="display: flex; flex-direction: column; width: 100%; height: 50%;">
                            <UploadImage @save="saveImage" :url="image" />
                            <label for="title">العنوان:</label>
                            <input id="title" type="input" style="margin-right: 10px;" v-model="albumTitle" />
                            <CommonSelect v-model="selectedCategory" :options="filteredOptions" label="القسم"
                                id="category" showParent />
                            <br />
                            <textarea class="form-control" v-model="albumDesc" placeholder="وصف..."></textarea>
                            <br />
                            <LanguageRadioButtons @selectedOption="languageChanged" :initialValue="langVal" />
                            <br />
                            <MediaAdditionDeletion :mediaType="mediaType" :media="mediaContents" :requests="state2"
                             @postMedia="onMediaAdded" />
                        <!--
                            <button class="btn" @click="addMedia">
                                {{ mediaType == 0 ? 'إضافة صورة' : 'إضافة صوت' }}
                            </button>
                            <div class="gallery1">
                                <div v-for="(media, index) in mediaContents" :key="generateRandomString(10)">
                                    <MediaContent :media="media" @deleteMedia="deleteMedia(index)"
                                        :mediaType="mediaType" :tempData="false"
                                        @updateMedia="updateMetaData(index, true, $event)" />
                                </div>

                                <div v-for="(url, index) in tempUrls">
                                    <MediaContent :media="url" @deleteMedia="deleteFile(index)" :mediaType="mediaType" :key="generateRandomString(10)"
                                        :tempData="true" @updateMedia="updateMetaData(index, false, $event)" />
                                </div>
                            </div-->
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closeModal">إلغاء</button>

                        <button type="button" class="btn btn-primary" @click="save"
                            :disabled="isSaving || !imageUploaded">{{ state==0 ? 'تجهيز' : 'حفظ' }}</button>
                        <p v-if="uploadProgress.total > 0">
                            رفع {{ uploadProgress.completed }}/{{ uploadProgress.total }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <ErrorDialog :msg="errorMessage" />
    </div>
</template>

<script setup>
import { onMounted, nextTick, ref, watch } from "vue";
import { ApiClient, MediaControllerApi, AlbumEntity, CategoryControllerApi, MediaEntity } from "../api/index.js"
import LanguageRadioButtons from './LanguageRadioButtons.vue';
import UploadImage from './UploadImage.vue';
import ErrorDialog from './ErrorDialog.vue'; // Import ErrorDialog
import { uploadAudioToFirebase, uploadFileToFirebase } from "../firebase/Utils.js";
import CommonSelect from './CommonSelect.vue';
import MediaAdditionDeletion from "./MediaAdditionDeletion.vue";

const props = defineProps(["albumToEdit", "mediaType"]);
const emit = defineEmits(["save"]);

const apiClient = new ApiClient();

const albumApi = new MediaControllerApi(apiClient);
const categoryApi = new CategoryControllerApi(apiClient);

const options = ref([]);
const filteredOptions = ref([]);

const title = ref("ألبوم جديد");
const selectedCategory = ref(null);
const selectedLanguage = ref("1");
const albumTitle = ref('');
const albumId = ref(null);
const langVal = ref('1');
const image = ref(null);
const mediaContents = ref([]);
const mediaType = ref(0);
const albumDesc = ref('');

const uploadProgress = ref({});

const isSaving = ref(false);
const state=ref(0);
const state2=ref(0);

const imageUploaded = ref(false);

const errorMessage = ref('');


watch(
    () => props.mediaType,
    (newMediaType) => {
        mediaType.value = newMediaType;
    }
);

watch(() => mediaType.value, (newMediaType) => {
    filterByParentId(newMediaType);
});



watch(
    () => props.albumToEdit,
    (newAlbum) => {
        uploadProgress.total = 0;
        if (newAlbum) {
            title.value = "تعديل ألبوم";
            albumId.value = newAlbum.id;
            selectedCategory.value = newAlbum.category;
            selectedLanguage.value = newAlbum.lang;
            albumTitle.value = newAlbum.title;
            langVal.value = newAlbum.lang;
            image.value = newAlbum.imgUrl || null;
            imageUploaded.value = Boolean(newAlbum.imgUrl);
            mediaContents.value = newAlbum.contentEntities;
            albumDesc.value = newAlbum.description;
            mediaType.value = newAlbum.mediaType;
        } else {
            resetForm();
        }
    }
);

function resetForm() {
    title.value = "ألبوم جديد";
    albumId.value = null;
    selectedLanguage.value = "1";
    selectedCategory.value = null;
    langVal.value = "1";
    image.value = null;
    mediaContents.value = [];
    albumDesc.value = '';
}

async function saveImage(imageData) {
    imageUploaded.value = false;
    const randomName = Math.random().toString(36).substring(2, 15);
    image.value = await uploadFileToFirebase(imageData, "albums/covers", `${randomName}.jpg`);
    imageUploaded.value = true;
}

/* function addMedia() {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.accept = mediaType.value === 0 ? acceptableImageTypes.join(',') : acceptableAudioTypes.join(',');
    fileInput.onchange = async (e) => {
        const files = Array.from(e.target.files);
        var showErr = false;
        files.forEach(file => {
            if ((mediaType.value === 0 && acceptableImageTypes.includes(file.type)) ||
                (mediaType.value === 1 && acceptableAudioTypes.includes(file.type))) {
                if (isBigFile(file)) {
                    showErr = true;
                    return;
                }
                tempFiles.value.push({ file });
                tempUrls.value.push({ url: URL.createObjectURL(file) });

            }
        });
        if (showErr) alert(" يجب أن لا يتجاوز حجم الملفات 1MB!")
    };
    fileInput.click();
}
 */
function languageChanged(lang) {
    selectedLanguage.value = lang.value;
}

function onMediaAdded(media){
    if(media.length>0){
        state.value=1;
        mediaContents.value=media;
    }
}

async function save() {
    if(state.value == 0) {
        state2.value=1;
        return;
    }
    isSaving.value = true;
    errorMessage.value = '';

    try {
        const album = new AlbumEntity();
        album.title = albumTitle.value || (() => { throw new Error('العنوان لا يمكن أن يكون فارغ') })();
        album.category = typeof (selectedCategory.value) == 'string' ?
            { id: selectedCategory.value || (() => { throw new Error('يرجئ إختيار القسم') })() } :
            selectedCategory.value || (() => { throw new Error('يرجئ إختيار القسم') })();

        album.lang = selectedLanguage.value || (() => { throw new Error('يرجئ إختيار اللغة') })();
        album.description = albumDesc.value || (() => { throw new Error('وصف الألبوم تاقص') })();
        album.coverImg = image.value || (() => { throw new Error('يرجى إضافة صورة الغلاف') })();
        album.published=true;
        album.mediaType = mediaType.value;

        // Handle media contents
        const updatedMediaContents = [];
        for (const media of mediaContents.value) {
            let mediaEntity = new MediaEntity();
            
            // If it's a new file that needs to be uploaded
            if (media.file) {
                const randomName = Math.random().toString(36).substring(2, 15);
                const fileExtension = media.file.name.split('.').pop(); // Get original file extension
                const uploadUrl = mediaType.value ==0? await uploadFileToFirebase(
                    media.file,  // Pass the actual file object
                    "albums/images", 
                    `${randomName}.${fileExtension}`
                ):await uploadAudioToFirebase(
                    media.file,  
                    "albums/audio", 
                    `${randomName}.${fileExtension}`
                );
                mediaEntity.mediaUrl = uploadUrl;
            } else {
                mediaEntity.mediaUrl = media.mediaUrl;
            }

            // Set metadata
            mediaEntity.title = media.title;
            mediaEntity.description = media.description;
            
            // If editing existing media, preserve the ID
            if (media.id) {
                mediaEntity.id = media.id;
            }

            updatedMediaContents.push(mediaEntity);
        }

        album.contentEntities = updatedMediaContents;
        album.addedAt = new Date().toISOString();
        album.author = { id: localStorage.getItem("author") }
        // console.log(album); 
        if (!albumId.value) {
            await new Promise((resolve, reject) => {
                albumApi.addMedia(album, localStorage.getItem("token"), (error, data, response) => {
                    if (error) reject(error);
                    else resolve(data);
                });
            });
        } else {
            album.id = albumId.value;
            await new Promise((resolve, reject) => {
                albumApi.updateMedia(album, localStorage.getItem("token"), (error, data, response) => {
                    if (error) reject(error);
                    else resolve(data);
                });
            });
        }
        location.reload();
    } catch (error) {
        errorMessage.value = error.message || 'حصل خطأ ما خلال الحفظ!';
    } finally {
        isSaving.value = false;
    }
}

onMounted(() => {
    nextTick(() => {
        const modalEls = document.querySelectorAll('.modal');
        modalEls.forEach((el) => {
            new window.bootstrap.Modal(el);

        });

        categoryApi.getMediaCategories({ page: 0, size: 100, isChild: true }, (error, data, response) => {
            if (!error) {
                options.value = data.content;
                filterByParentId(0);
            } else {
                // console.error(error);
            }
        });
    });
});

function closeModal() {
    document.location.reload();
}


watch(() => selectedCategory, (newValue) => {
    // console.log('Category changed:', newValue); // For debugging
    // console.log('Category changed2:', selectedCategory.value); // For debugging
});


const filterByParentId = (newMediaType) => {
    var parentId = newMediaType == 0 ? "photos" : "audios";
    filteredOptions.value = options.value.filter(item => item.parent.id === parentId)
}
</script>

<style scoped>
.modal-static {
    pointer-events: none;
    /* Disables interactions */
}

.modal-static .modal-content {
    pointer-events: auto;
    /* Enables interactions inside the modal content */
}

.gallery1 {
    display: inline-block;
    white-space: nowrap;
    overflow-x: auto;
    /* Add horizontal scrollbar if content overflows */
}

.gallery1>div {
    display: inline-block;
    vertical-align: top;
    /* Aligns items vertically at the top */
}

*::-webkit-scrollbar {
    width: 2px;
    background-color: rgb(255, 255, 255);
}

*::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(255, 253, 253);
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(241, 88, 39);
    outline: 1px solid rgb(241, 88, 39);
}

.modal-content {
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    overflow: hidden;
}

.modal-header {
    background-color: var(--primary-color);
    color: var(--text-light);
    padding: 1rem 1.5rem;
}

.modal-title {
    font-weight: bold;
}

.modal-body {
    background-color: var(--background-light);
    padding: 1.5rem;
}

.modal-footer {
    background-color: var(--background-light);
    border-top: 1px solid var(--border-color);
    padding: 1rem 1.5rem;
}

.btn {
    border-radius: 0.25rem;
    padding: 0.75rem 1.5rem;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
}

.btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--text-light);
}

.btn-primary:hover:not(:disabled) {
    background-color: var(--primary-light);
    border-color: var(--primary-light);
}

.btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
    color: var(--text-light);
}

.btn-secondary:hover {
    background-color: #5a6268;
    border-color: #545b62;
}

.btn:disabled {
    opacity: 0.65;
    cursor: not-allowed;
}

.form-control {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    transition: border-color 0.2s ease-in-out;
    margin-bottom: 1rem;
}

.form-control:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(89, 3, 5, 0.25);
}
</style>

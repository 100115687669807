<template>
    <div v-if="showErrorDialog" class="error-dialog-overlay" @click="closeErrorDialog">
        <div class="error-dialog" @click.stop>
            <p>{{ props.msg }}</p>
            <div class="button-group">
                <button @click="closeErrorDialog" class="btn btn-action delete-button">إغلاق</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const showErrorDialog = ref(false);
const props = defineProps({
  msg: {
    type: String,
    default: ''
  }
});

watch(() => props.msg, (newVal) => {
  showErrorDialog.value = newVal !== '';
});

const closeErrorDialog = () => {
  showErrorDialog.value = false;
}
</script>

<style scoped>
.error-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.error-dialog {
    position: relative;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.close-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
    background-color: #3498db;
    color: white;
}

.close-btn:hover {
    background-color: #2980b9;
}
</style>

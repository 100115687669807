/*
 * Yafeapedia
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.64
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import AuthorEntity from './AuthorEntity';

/**
 * The UsersEntity model module.
 * @module model/UsersEntity
 * @version 1.0.1
 */
export default class UsersEntity {
  /**
   * Constructs a new <code>UsersEntity</code>.
   * @alias module:model/UsersEntity
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>UsersEntity</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UsersEntity} obj Optional instance to populate.
   * @return {module:model/UsersEntity} The populated <code>UsersEntity</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UsersEntity();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('username'))
        obj.username = ApiClient.convertToType(data['username'], 'String');
      if (data.hasOwnProperty('password'))
        obj.password = ApiClient.convertToType(data['password'], 'String');
      if (data.hasOwnProperty('privileges'))
        obj.privileges = ApiClient.convertToType(data['privileges'], 'String');
      if (data.hasOwnProperty('enabled'))
        obj.enabled = ApiClient.convertToType(data['enabled'], 'Boolean');
      if (data.hasOwnProperty('author'))
        obj.author = AuthorEntity.constructFromObject(data['author']);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
UsersEntity.prototype.id = undefined;

/**
 * @member {String} username
 */
UsersEntity.prototype.username = undefined;

/**
 * @member {String} password
 */
UsersEntity.prototype.password = undefined;

/**
 * @member {String} privileges
 */
UsersEntity.prototype.privileges = undefined;

/**
 * @member {Boolean} enabled
 */
UsersEntity.prototype.enabled = undefined;

/**
 * @member {module:model/AuthorEntity} author
 */
UsersEntity.prototype.author = undefined;


/*
 * Yafeapedia
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.64
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from "../ApiClient";
import ArticleDTO from '../model/ArticleDTO';
import ArticleEntity from '../model/ArticleEntity';
import PageArticleDTO from '../model/PageArticleDTO';

/**
* ArticleController service.
* @module api/ArticleControllerApi
* @version 1.0.1
*/
export default class ArticleControllerApi {

    /**
    * Constructs a new ArticleControllerApi. 
    * @alias module:api/ArticleControllerApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the addArticle operation.
     * @callback moduleapi/ArticleControllerApi~addArticleCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ArticleDTO{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/ArticleEntity} body 
     * @param {String} authorization 
     * @param {module:api/ArticleControllerApi~addArticleCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    addArticle(body, authorization, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling addArticle");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling addArticle");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        'Authorization': authorization
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = ArticleDTO;

      return this.apiClient.callApi(
        '/v1/articles/manage/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the changePublicationStatues2 operation.
     * @callback moduleapi/ArticleControllerApi~changePublicationStatues2Callback
     * @param {String} error Error message, if any.
     * @param {module:model/ArticleDTO{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} id 
     * @param {String} authorization 
     * @param {module:api/ArticleControllerApi~changePublicationStatues2Callback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    changePublicationStatues2(id, authorization, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling changePublicationStatues2");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling changePublicationStatues2");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        'Authorization': authorization
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = ArticleDTO;

      return this.apiClient.callApi(
        '/v1/articles/manage/publication/{id}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the deleteArticle operation.
     * @callback moduleapi/ArticleControllerApi~deleteArticleCallback
     * @param {String} error Error message, if any.
     * @param {Object{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} id 
     * @param {String} authorization 
     * @param {module:api/ArticleControllerApi~deleteArticleCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    deleteArticle(id, authorization, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteArticle");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling deleteArticle");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        'Authorization': authorization
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/v1/articles/manage/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getAllArticles operation.
     * @callback moduleapi/ArticleControllerApi~getAllArticlesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PageArticleDTO{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} authorization 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page  (default to <.>)
     * @param {Number} opts.size  (default to <.>)
     * @param {module:api/ArticleControllerApi~getAllArticlesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getAllArticles(authorization, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling getAllArticles");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'page': opts['page'],'size': opts['size']
      };
      let headerParams = {
        'Authorization': authorization
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = PageArticleDTO;

      return this.apiClient.callApi(
        '/v1/articles/manage/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getArticle operation.
     * @callback moduleapi/ArticleControllerApi~getArticleCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ArticleDTO{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} id 
     * @param {module:api/ArticleControllerApi~getArticleCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getArticle(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getArticle");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = ArticleDTO;

      return this.apiClient.callApi(
        '/v1/articles/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getArticlesByCategory operation.
     * @callback moduleapi/ArticleControllerApi~getArticlesByCategoryCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PageArticleDTO{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} cat 
     * @param {Number} lang 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page  (default to <.>)
     * @param {Number} opts.size  (default to <.>)
     * @param {module:api/ArticleControllerApi~getArticlesByCategoryCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getArticlesByCategory(cat, lang, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'cat' is set
      if (cat === undefined || cat === null) {
        throw new Error("Missing the required parameter 'cat' when calling getArticlesByCategory");
      }
      // verify the required parameter 'lang' is set
      if (lang === undefined || lang === null) {
        throw new Error("Missing the required parameter 'lang' when calling getArticlesByCategory");
      }

      let pathParams = {
        'cat': cat,'lang': lang
      };
      let queryParams = {
        'page': opts['page'],'size': opts['size']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = PageArticleDTO;

      return this.apiClient.callApi(
        '/v1/articles/{cat}/{lang}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getTop5ArticlesByCategory operation.
     * @callback moduleapi/ArticleControllerApi~getTop5ArticlesByCategoryCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/ArticleDTO>{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} catId 
     * @param {Number} lang 
     * @param {Boolean} isChild 
     * @param {module:api/ArticleControllerApi~getTop5ArticlesByCategoryCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getTop5ArticlesByCategory(catId, lang, isChild, callback) {
      
      let postBody = null;
      // verify the required parameter 'catId' is set
      if (catId === undefined || catId === null) {
        throw new Error("Missing the required parameter 'catId' when calling getTop5ArticlesByCategory");
      }
      // verify the required parameter 'lang' is set
      if (lang === undefined || lang === null) {
        throw new Error("Missing the required parameter 'lang' when calling getTop5ArticlesByCategory");
      }
      // verify the required parameter 'isChild' is set
      if (isChild === undefined || isChild === null) {
        throw new Error("Missing the required parameter 'isChild' when calling getTop5ArticlesByCategory");
      }

      let pathParams = {
        'catId': catId,'lang': lang,'isChild': isChild
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = [ArticleDTO];

      return this.apiClient.callApi(
        '/v1/articles/{catId}/{lang}/{isChild}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the updateArticle operation.
     * @callback moduleapi/ArticleControllerApi~updateArticleCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ArticleDTO{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/ArticleEntity} body 
     * @param {String} authorization 
     * @param {module:api/ArticleControllerApi~updateArticleCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    updateArticle(body, authorization, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateArticle");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling updateArticle");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        'Authorization': authorization
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = ArticleDTO;

      return this.apiClient.callApi(
        '/v1/articles/manage/update', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
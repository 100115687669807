/*
 * Yafeapedia
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.64
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The PasswordResetEntity model module.
 * @module model/PasswordResetEntity
 * @version 1.0.1
 */
export default class PasswordResetEntity {
  /**
   * Constructs a new <code>PasswordResetEntity</code>.
   * @alias module:model/PasswordResetEntity
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PasswordResetEntity</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PasswordResetEntity} obj Optional instance to populate.
   * @return {module:model/PasswordResetEntity} The populated <code>PasswordResetEntity</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PasswordResetEntity();
      if (data.hasOwnProperty('np'))
        obj.np = ApiClient.convertToType(data['np'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} np
 */
PasswordResetEntity.prototype.np = undefined;


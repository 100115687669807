<template>
    <div>
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true" ref="modal">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header text-white">
                        <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
                    </div>
                    <div class="modal-body">
                        <div class="article-form">
                            <UploadImage @save="saveImage" :url="image" class="mb-4" />

                            <div class="form-group">
                                <label for="title">العنوان:</label>
                                <input id="title" type="input" class="form-control" v-model="articleTitle" />
                            </div>

                            <CommonSelect v-show="isHPUser"  v-model="selectedCategory" :options="options" label="القسم" id="category"
                                showParent />
                            <LanguageRadioButtons @selectedOption="languageChanged" :initialValue="langVal.value"
                                class="my-3" />

                            <WYSIWYG :content="editorContent" v-model="editorContent" class="mb-3" />

                            <div class="form-group">
                                <label for="keywords">الكلمات المفتاحية:</label>
                                <input id="keywords" type="input" class="form-control" v-model="keywords" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closeModal">إلغاء</button>
                        <button type="button" class="btn btn-primary" @click="save"
                            :disabled="!imageUploaded">حفظ</button>
                    </div>
                </div>
            </div>
        </div>
        <ErrorDialog :msg="errorMessage" />
    </div>
</template>

<script setup>
// import AddEditArticle from "./AddEditArticle.vue";
import WYSIWYG from '@/components/WYSIWYG.vue';
import { onMounted, nextTick, ref, watch } from "vue";
import { ApiClient, ArticleControllerApi, ArticleEntity, CategoryControllerApi, PagesControllerApi } from "../api/index.js"
import LanguageRadioButtons from './LanguageRadioButtons.vue';
import UploadImage from "./UploadImage.vue";
import { uploadFileToFirebase, saveToFirebase } from "../firebase/Utils.js";
import ErrorDialog from './ErrorDialog.vue';
import CommonSelect from './CommonSelect.vue';


const props = defineProps(["articleToEdit"]);
const emit = defineEmits(["save"]);

const apiClient = new ApiClient();

const articlesApi = new ArticleControllerApi(apiClient);
const categoryApi = new CategoryControllerApi(apiClient);
const pagesApi = new PagesControllerApi(apiClient);

const options = ref([]);

const title = ref("مقال جديد");
const selectedCategory = ref(null);
const selectedLanguage = ref("1");
const editorContent = ref('');
// const editorContentId = ref([]);
const articleTitle = ref('');
const articleId = ref(null);
const langVal = ref('1');
const image = ref(null);
const imageUploaded = ref(false);
const keywords = ref('');
const isHPUser = JSON.parse(localStorage.getItem("isHPUser") || "false");

const errorMessage = ref('');

watch(() => selectedCategory, (newValue) => {
    // console.log('Category changed2 :', newValue); // For debugging
});


watch(
    () => props.articleToEdit,
    (newArticle) => {
        if (newArticle) {
            title.value = "تعديل مقال";
            articleId.value = newArticle.id;
            selectedCategory.value = newArticle.category;
            selectedLanguage.value = newArticle.lang;
            articleTitle.value = newArticle.title;
            langVal.value = newArticle.lang;
            image.value = newArticle.imgUrl;
            editorContent.value = "";
            editorContent.value = newArticle.content;
            keywords.value = newArticle.keywords;
            imageUploaded.value = true;
           
        } else {
            title.value = "مقال جديد";
            articleId.value = null;
            selectedLanguage.value = "1";
            selectedCategory.value = null;
            langVal.value = "1";
            image.value = null;
            editorContent.value = "";
            keywords.value = "";
        }
    }
);

//for cover I guess
async function saveImage(imageData) {
    imageUploaded.value = false;
    const randomName = Math.random().toString(36).substring(2, 15);
    image.value = await uploadFileToFirebase(imageData, "articles/covers", `${randomName}.jpg`);
    imageUploaded.value = true;
}

onMounted(() => {
    nextTick(() => {
        // Initialize Bootstrap modals
        const modalEls = document.querySelectorAll('.modal');
        modalEls.forEach((el) => {
            new window.bootstrap.Modal(el);
        });

        //get categories
        categoryApi.getCategories(localStorage.getItem("token"), { page: 0, size: 100, isChild: true }, (error, data, response) => {
            if (error) {
                console.error(error);
                return;
            }
            options.value = data.content;
        });
    });
});

function languageChanged(lang) {
    selectedLanguage.value = lang.value;
}

function save() {
    try {
        if (imageUploaded.value == false) {
            errorMessage.value = "لم يتم رفع صورة بعد!";
            return;
        }
        var article = new ArticleEntity();

        // const categoryIdValue = (selectedCategory.value||'').trim();
        const langValue = selectedLanguage.value;//(selectedLanguage.value || '').trim();
        const titleValue = articleTitle.value;// (articleTitle.value).trim();
        const contentValue = (editorContent.value || '').trim();
        const keywordsValue = (keywords.value || '').trim();
        article.title = titleValue || (() => { throw new Error('العنوان لا يمكن أن يكون فارغ') })();
        if (isHPUser == true || articleId.value) {
            article.category = typeof (selectedCategory.value) == 'string' ?
                { id: selectedCategory.value || (() => { throw new Error('يرجئ إختيار القسم') })() } :
                selectedCategory.value || (() => { throw new Error('يرجئ إختيار القسم') })();
        }
        article.lang = langValue || (() => { throw new Error('يرجئ إختيار اللغة') })();
        article.content = contentValue || (() => { throw new Error('يبدو أن محتوى المقالة فارغ') })();
        article.addedAt = "";
        article.published = true;
        if (!articleId.value) {
            article.author = { id: localStorage.getItem("author") };
            article.lastEditBy = { id: parseInt(localStorage.getItem("author")) };
            article.id = "";
        }
        else {
            article.id = articleId.value;
            // content.id = editorContentId.value[0];
            article.lastEditBy = { id: localStorage.getItem("author") };
            article.author = { id: parseInt(localStorage.getItem("author")) };
        }
        article.deletedAt = "";
        article.lastEdit = "";
        article.published = "";
        article.coverImg = image.value;
        article.keywords = keywordsValue;
        if (!articleId.value) {
            if (isHPUser == true)
                articlesApi.addArticle(article, localStorage.getItem("token"), (error, data, response) => {
                    if (error == null)
                        location.reload();
                    else {
                        errorMessage.value = error.message || 'حصل خطأ ما خلال الحفظ!';
                        return;
                    }

                });
            else
                pagesApi.addPArticle(article, localStorage.getItem("token"), (error, data, response) => {
                    if (error == null)
                        location.reload();
                    else {
                        errorMessage.value = error.message || 'حصل خطأ ما خلال الحفظ!';
                        return;
                    }

                });

        } else {
            if (isHPUser == true)
                articlesApi.updateArticle(article, localStorage.getItem("token"), (error, data, response) => {
                    if (error == null)
                        location.reload();
                    else {
                        errorMessage.value = error.message || 'حصل خطأ ما خلال الحفظ!';
                        return;
                    }

                });
            else
                pagesApi.updatePArticle(article, localStorage.getItem("token"), (error, data, response) => {
                    if (error == null)
                        location.reload();
                    else {
                        errorMessage.value = error.message || 'حصل خطأ ما خلال الحفظ!';
                        return;
                    }

                });
        }
    } catch (error) {
        errorMessage.value = error.message || 'خطأ غير متوقع!';
    }

}

function closeModal() {
    document.location.reload();
}
</script>

<style scoped>
.article-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
    background-color: var(--background-light);
    border-radius: 0.5rem;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--primary-dark);
    font-weight: bold;
}

.form-control {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    transition: border-color 0.2s ease-in-out;
}

.form-control:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(89, 3, 5, 0.25);
}

.modal-content {
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    overflow: hidden;
}

.modal-header {
    background-color: var(--primary-color);
    color: var(--text-light);
    padding: 1rem 1.5rem;
}

.modal-title {
    font-weight: bold;
}

.modal-footer {
    background-color: var(--background-light);
    border-top: 1px solid var(--border-color);
    padding: 1rem 1.5rem;
}

.btn {
    border-radius: 0.25rem;
    padding: 0.75rem 1.5rem;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
}

.btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--text-light);
}

.btn-primary:hover:not(:disabled) {
    background-color: var(--primary-light);
    border-color: var(--primary-light);
}

.btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
    color: var(--text-light);
}

.btn-secondary:hover {
    background-color: #5a6268;
    border-color: #545b62;
}

.btn:disabled {
    opacity: 0.65;
    cursor: not-allowed;
}

select.form-control {
    padding: unset !important;
}
</style>

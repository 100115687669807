/*
 * Yafeapedia
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.61
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from "../ApiClient";
import PageAlbumDTO from '../model/PageAlbumDTO';
import PageArticleDTO from '../model/PageArticleDTO';
import PageCategoryDTO from '../model/PageCategoryDTO';
import PageUserDTO from '../model/PageUserDTO';

/**
* SearchController service.
* @module api/SearchControllerApi
* @version 1.0.1
*/
export default class SearchControllerApi {

    /**
    * Constructs a new SearchControllerApi. 
    * @alias module:api/SearchControllerApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the searchArticles operation.
     * @callback moduleapi/SearchControllerApi~searchArticlesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PageArticleDTO{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} page 
     * @param {Number} size 
     * @param {String} query 
     * @param {Number} lang 
     * @param {module:api/SearchControllerApi~searchArticlesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    searchArticles(page, size, query, lang, callback) {
      
      let postBody = null;
      // verify the required parameter 'page' is set
      if (page === undefined || page === null) {
        throw new Error("Missing the required parameter 'page' when calling searchArticles");
      }
      // verify the required parameter 'size' is set
      if (size === undefined || size === null) {
        throw new Error("Missing the required parameter 'size' when calling searchArticles");
      }
      // verify the required parameter 'query' is set
      if (query === undefined || query === null) {
        throw new Error("Missing the required parameter 'query' when calling searchArticles");
      }
      // verify the required parameter 'lang' is set
      if (lang === undefined || lang === null) {
        throw new Error("Missing the required parameter 'lang' when calling searchArticles");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'page': page,'size': size,'query': query,'lang': lang
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = PageArticleDTO;

      return this.apiClient.callApi(
        '/v1/search/articles', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the searchCategories operation.
     * @callback moduleapi/SearchControllerApi~searchCategoriesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PageCategoryDTO{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} page 
     * @param {Number} size 
     * @param {String} query 
     * @param {String} authorization 
     * @param {module:api/SearchControllerApi~searchCategoriesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    searchCategories(page, size, query, authorization, callback) {
      
      let postBody = null;
      // verify the required parameter 'page' is set
      if (page === undefined || page === null) {
        throw new Error("Missing the required parameter 'page' when calling searchCategories");
      }
      // verify the required parameter 'size' is set
      if (size === undefined || size === null) {
        throw new Error("Missing the required parameter 'size' when calling searchCategories");
      }
      // verify the required parameter 'query' is set
      if (query === undefined || query === null) {
        throw new Error("Missing the required parameter 'query' when calling searchCategories");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling searchCategories");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'page': page,'size': size,'query': query
      };
      let headerParams = {
        'Authorization': authorization
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = PageCategoryDTO;

      return this.apiClient.callApi(
        '/v1/search/categories', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the searchMedia operation.
     * @callback moduleapi/SearchControllerApi~searchMediaCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PageAlbumDTO{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} page 
     * @param {Number} size 
     * @param {String} query 
     * @param {Number} lang 
     * @param {module:api/SearchControllerApi~searchMediaCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    searchMedia(page, size, query, lang, callback) {
      
      let postBody = null;
      // verify the required parameter 'page' is set
      if (page === undefined || page === null) {
        throw new Error("Missing the required parameter 'page' when calling searchMedia");
      }
      // verify the required parameter 'size' is set
      if (size === undefined || size === null) {
        throw new Error("Missing the required parameter 'size' when calling searchMedia");
      }
      // verify the required parameter 'query' is set
      if (query === undefined || query === null) {
        throw new Error("Missing the required parameter 'query' when calling searchMedia");
      }
      // verify the required parameter 'lang' is set
      if (lang === undefined || lang === null) {
        throw new Error("Missing the required parameter 'lang' when calling searchMedia");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'page': page,'size': size,'query': query,'lang': lang
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = PageAlbumDTO;

      return this.apiClient.callApi(
        '/v1/search/media', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the searchPArticles operation.
     * @callback moduleapi/SearchControllerApi~searchPArticlesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PageArticleDTO{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} page 
     * @param {Number} size 
     * @param {String} query 
     * @param {Number} lang 
     * @param {Number} id 
     * @param {module:api/SearchControllerApi~searchPArticlesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    searchPArticles(page, size, query, lang, id, callback) {
      
      let postBody = null;
      // verify the required parameter 'page' is set
      if (page === undefined || page === null) {
        throw new Error("Missing the required parameter 'page' when calling searchPArticles");
      }
      // verify the required parameter 'size' is set
      if (size === undefined || size === null) {
        throw new Error("Missing the required parameter 'size' when calling searchPArticles");
      }
      // verify the required parameter 'query' is set
      if (query === undefined || query === null) {
        throw new Error("Missing the required parameter 'query' when calling searchPArticles");
      }
      // verify the required parameter 'lang' is set
      if (lang === undefined || lang === null) {
        throw new Error("Missing the required parameter 'lang' when calling searchPArticles");
      }
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling searchPArticles");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'page': page,'size': size,'query': query,'lang': lang,'id': id
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = PageArticleDTO;

      return this.apiClient.callApi(
        '/v1/search/particles', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the searchUsersByUserName operation.
     * @callback moduleapi/SearchControllerApi~searchUsersByUserNameCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PageUserDTO{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} page 
     * @param {Number} size 
     * @param {String} query 
     * @param {String} authorization 
     * @param {module:api/SearchControllerApi~searchUsersByUserNameCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    searchUsersByUserName(page, size, query, authorization, callback) {
      
      let postBody = null;
      // verify the required parameter 'page' is set
      if (page === undefined || page === null) {
        throw new Error("Missing the required parameter 'page' when calling searchUsersByUserName");
      }
      // verify the required parameter 'size' is set
      if (size === undefined || size === null) {
        throw new Error("Missing the required parameter 'size' when calling searchUsersByUserName");
      }
      // verify the required parameter 'query' is set
      if (query === undefined || query === null) {
        throw new Error("Missing the required parameter 'query' when calling searchUsersByUserName");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling searchUsersByUserName");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'page': page,'size': size,'query': query
      };
      let headerParams = {
        'Authorization': authorization
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = PageUserDTO;

      return this.apiClient.callApi(
        '/v1/search/users', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
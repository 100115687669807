/*
 * Yafeapedia
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.64
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The DeletedItemDTO model module.
 * @module model/DeletedItemDTO
 * @version 1.0.1
 */
export default class DeletedItemDTO {
  /**
   * Constructs a new <code>DeletedItemDTO</code>.
   * @alias module:model/DeletedItemDTO
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>DeletedItemDTO</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DeletedItemDTO} obj Optional instance to populate.
   * @return {module:model/DeletedItemDTO} The populated <code>DeletedItemDTO</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DeletedItemDTO();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('author'))
        obj.author = ApiClient.convertToType(data['author'], 'String');
      if (data.hasOwnProperty('category'))
        obj.category = ApiClient.convertToType(data['category'], 'String');
      if (data.hasOwnProperty('isMedia'))
        obj.isMedia = ApiClient.convertToType(data['isMedia'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
DeletedItemDTO.prototype.id = undefined;

/**
 * @member {String} title
 */
DeletedItemDTO.prototype.title = undefined;

/**
 * @member {String} author
 */
DeletedItemDTO.prototype.author = undefined;

/**
 * @member {String} category
 */
DeletedItemDTO.prototype.category = undefined;

/**
 * @member {Number} isMedia
 */
DeletedItemDTO.prototype.isMedia = undefined;


<template>
    <div class="upload-container">
        <!-- Hidden file input -->
        <input 
            type="file" 
            class="hidden-input" 
            id="customFile" 
            ref="fileInput" 
            @change="onFileChange" 
        />
        
        <!-- Upload area -->
        <div 
            class="upload-area"
            @click="triggerFileInput"
            @dragover.prevent="isDragging = true"
            @dragleave.prevent="isDragging = false"
            @drop.prevent="handleDrop"
            :class="{ 
                'has-image': previewUrl,
                'is-dragging': isDragging,
                'is-loading': isLoading 
            }"
        >
            <template v-if="!previewUrl">
                <i class="fas fa-cloud-upload-alt upload-icon"></i>
                <p class="upload-text">انقر لتحميل صورة</p>
                <p class="upload-hint">أو اسحب وأفلت الملف هنا</p>
                <p class="upload-limits">JPG, PNG or GIF • Max 1MB</p>
            </template>
            
            <img v-else :src="previewUrl" alt="Preview" class="preview-image" />

            <!-- Loading overlay -->
            <div v-if="isLoading" class="loading-overlay">
                <div class="spinner"></div>
                <p>جارٍ التحميل...</p>
            </div>
        </div>
        
        <!-- Error message -->
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        
        <!-- File name display -->
        <p v-if="fileName" class="file-name">
            <i class="fas fa-file-image"></i>
            {{ fileName }}
        </p>
    </div>
</template>

<script setup>
import { ref,watch } from 'vue'

const emit = defineEmits(['save'])
const props=defineProps(["url"]);

const fileInput = ref(null)
const file = ref(null)
const fileName = ref('')
const previewUrl = ref(null)
const isDragging = ref(false)
const isLoading = ref(false)
const errorMessage = ref('')

const MAX_FILE_SIZE = 1 * 1024 * 1024 // 1MB
const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/gif']

function triggerFileInput() {
    fileInput.value.click()
}

function handleDrop(e) {
    isDragging.value = false
    const droppedFile = e.dataTransfer.files[0]
    if (droppedFile) validateAndProcessFile(droppedFile)
}

function validateAndProcessFile(file) {
    errorMessage.value = ''
    
    if (!ALLOWED_TYPES.includes(file.type)) {
        errorMessage.value = 'يرجئ إختيار صورة متوافقة'
        return
    }
    
    if (file.size > MAX_FILE_SIZE) {
        errorMessage.value = 'حجم الملف يجب أن يكون أقل من 1MB.'
        return
    }

    isLoading.value = true
    file.value = file
    fileName.value = file.name
    
    const reader = new FileReader()
    reader.onload = e => {
        previewUrl.value = e.target.result
        emit('save', file)
        isLoading.value = false
    }
    reader.onerror = () => {
        errorMessage.value = 'فشل فتح الملف'
        isLoading.value = false
    }
    reader.readAsDataURL(file)
}

function onFileChange(e) {
    const selectedFile = e.target.files[0]
    if (selectedFile) validateAndProcessFile(selectedFile)
}

watch(() => props.url, (newUrl) => {
    if (newUrl) {
        previewUrl.value = newUrl
        file.value = null
        fileName.value = ''
    }else {
        previewUrl.value = null
        file.value = null
        fileName.value = ''
    }
}, { immediate: true })

</script>

<style scoped>
.upload-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.hidden-input {
    display: none;
}

.upload-area {
    border: 2px dashed var(--primary-color);
    border-radius: 8px;
    padding: 2rem;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: var(--background-light);
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    transform: scale(1);
}

.upload-area:hover {
    border-color: var(--primary-color);
    background-color: rgba(var(--primary-color-rgb), 0.05);
}

.upload-icon {
    font-size: 3rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
}

.upload-text {
    font-size: 1.2rem;
    color: var(--text-dark);
    margin-bottom: 0.5rem;
}

.upload-hint {
    font-size: 0.9rem;
    color: var(--text-muted);
}

.preview-image {
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
    border-radius: 4px;
    transition: transform 0.3s ease;
}

.preview-image:hover {
    transform: scale(1.05);
}

.file-name {
    margin-top: 1rem;
    padding: 0.5rem;
    background-color: var(--background-light);
    border-radius: 4px;
    text-align: center;
}

.has-image {
    border-style: solid;
    padding: 0.5rem;
}

.is-dragging {
    border-color: var(--primary-color);
    background-color: rgba(var(--primary-color-rgb), 0.1);
    transform: scale(1.02);
}

.upload-limits {
    font-size: 0.8rem;
    color: var(--text-muted);
    margin-top: 0.5rem;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.spinner {
    width: 40px;
    height: 40px;
    border: 3px solid var(--background-light);
    border-top-color: var(--primary-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.error-message {
    color: var(--danger-color, #dc3545);
    text-align: center;
    margin-top: 0.5rem;
    font-size: 0.9rem;
}

@keyframes spin {
    to { transform: rotate(360deg); }
}
</style>
